import './style.css'
import * as THREE from 'three'
import { DeviceOrientationControls } from 'three/examples/jsm/controls/DeviceOrientationControls.js'

// Sizes
const sizes = {
    width: 800,
    height: 600
}

let camera, scene, renderer, controls;
const canvas = document.querySelector('canvas.webgl')

const startButton = document.getElementById( 'startButton' );
    startButton.addEventListener( 'click', function () {
        init()
        tick()
    } );


function init() {

    const overlay = document.getElementById( 'overlay' );
    overlay.remove();
    // Camera
    camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 1, 1000)
    // Controls
    controls = new DeviceOrientationControls( camera )
    // Canvas

    



    // Scene
    scene = new THREE.Scene()

    // Texture
    const imageSource = '360texture.jpg'
    const textureLoader = new THREE.TextureLoader()
    const texture = textureLoader.load(imageSource)
    const material2 = new THREE.MeshBasicMaterial({ map: texture })

    // Sphere
    const geometry = new THREE.SphereGeometry( 500, 60, 40 );
    geometry.scale( - 1, 1, 1 );
    const kugel = new THREE.Mesh( geometry, material2 );
    scene.add( kugel );

    //camera.position.z = 4
    scene.add(camera)


    // Renderer
    renderer = new THREE.WebGLRenderer({
        canvas: canvas
    })
    renderer.setSize(sizes.width, sizes.height)
    }



const tick = () =>
{
    controls.update()
    renderer.render(scene, camera)

    window.requestAnimationFrame(tick)
}

